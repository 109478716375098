import React from 'react'
import { useStaticQuery, graphql, Link as GLink } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)
  const image = getImage(data.avatar)

  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Me'
            subheader="Designing for the user experience is not about adding frills and whistles for the sake of making something look or feel good. 
            It's about understanding what people need and providing it in the most intuitive, efficient, and satisfying way possible."
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img image={image} />
            <Button as={GLink} to='/contact' sx={styles.button}>
              Contact Me
            </Button>
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='My Story'>
                <Text variant='p'>
                  My name is Justin Chambers, and I'm someone who is passionate about leading an active and 
                  fulfilling life. I've always been someone who enjoys pushing my limits and trying new things, and that's 
                  evident in the variety of interests I have.
                </Text>
                <Text variant='p'>
                  I'm an avid runner, and I love nothing more than hitting the pavement or hitting the trails for a good run. 
                  Running helps me stay in shape and it's a great way for me to clear my head and relax.
                </Text>
                <Text variant='p'>
                  Cycling is another hobby of mine. I love the sense of freedom and adventure that comes with exploring new roads and trails on two wheels. 
                  I also like the challenge of pushing myself to go faster and farther. 
                  Whether I'm out on a casual ride with friends or training for a race, cycling always puts a smile on my face.
                </Text>
                <Text variant='p'>
                  When I'm not out being active, you'll often find me sitting at a chessboard, studying and playing. 
                  It's a challenging game that requires focus and strategy. It's also a wonderful way to keep my mind sharp and improve my 
                  problem-solving skills.
                </Text>
                <Text variant='p'>
                  I also love spending time on the water. I enjoy boating and surfing. I find the ocean peaceful and exhilarating at the 
                  same time. Boating is a good opportunity to relax, and enjoy the sun, go fishing or swim. And when it comes to surfing, 
                  there's nothing like catching a wave and riding it all the way to the shore.
                </Text>
                <Text variant='p'>
                  Lastly, I'm a photography enthusiast. I like to capture moments that tell a story. I'm always looking for new subjects to 
                  photograph, whether it's landscapes, people, or animals. There's something about being behind the lens that allows me to 
                  see the world in a new and different way.
                </Text>
                <Text variant='p'>
                  Overall, I believe in living a balanced life, one that includes a mix of physical activity, mental stimulation, and creativity. 
                  And I feel that my interests help me achieve that balance. I'm excited to see where these passions will take me in the future.    
                </Text>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='Product Development'>
                <Card variant='paper'>
                As a leader in innovative product development, I have successfully headed product development for a startup companies and large multi-nationals, 
                building cutting-edge solutions to improve collaboration, streamline processes and enhance efficiency. 
                My ability to manage domestic and international contractors, negotiate and prepare contracts and statements of work, and ensure compliance within 
                budget and deadlines has been key in driving the success of the company. 
                With a strong understanding of the latest industry trends and technologies, I am able to lead a team to deliver exceptional solutions that meet the 
                needs of our clients. My expertise in product development has made me a valuable asset to any organization.
                </Card>
              </Section>
              <Divider />
              <Section title='Mobile Product Strategy Leader'>
                <Card variant='paper'>
                I am a Mobile Product Strategy Leader with a proven track record of successfully driving product development and 
                delivery for top-tier companies. With over 20 years of experience in the mobile industry, I have a deep understanding of the latest trends and technologies. 
                I have led cross-functional teams to deliver innovative mobile products that have driven business growth and improved user experiences. 
                My expertise in product strategy and roadmapping, as well as my ability to navigate and understand the competitive landscape, 
                has enabled me to deliver products that meet the needs of customers and exceed business objectives. I am a creative thinker, problem solver, and effective communicator, 
                and I am passionate about creating products that have a meaningful impact on people's lives.
                </Card>
              </Section>
              <Divider />
              <Section title='Mobile Strategist'>
                <Card variant='paper'>
                I am a Mobile Strategist, specializing in creating and implementing strategic plans for mobile products and services. 
                I am responsible for identifying market opportunities and developing a vision for how mobile technology can be leveraged to meet the needs of 
                customers and achieve business goals. This includes conducting market research, analyzing competitor activities, and identifying key trends and technologies 
                that will shape the future of mobile. I play a critical role in the product development process, working closely with cross-functional 
                teams to define product requirements, prioritize features and ensure that the final product aligns with the company's overall strategy. Additionally, 
                I help to build and maintain relationships with key stakeholders, such as customers, partners, and industry experts, 
                to ensure that the company's mobile efforts stay aligned with industry trends and customer needs.
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`
